<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="colorSnak"
    >
      {{ text }}
      <v-btn
        color="black"
        text
        @click="snackbar = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
    <base-material-card
      color="indigo"
      icon="mdi-google-circles-extended"
      title="Listado de procedimientos"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="procedimientos"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            color="white"
          >
            <v-spacer />
            <v-dialog
              v-model="dialog"
              scrollable
              persistent
              max-width="85%"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  color="primary"
                  fab
                  dark
                  class="mb-2"
                  v-on="on"
                >
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5 font-weight-bold mt-3">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-form
                    ref="form"
                    v-model="valid"
                    :lazy-validation="lazy"
                  >
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="5"
                        >
                          <v-text-field
                            v-model="editedItem.nombre"
                            :rules="[v => !!v || 'El nombre es requerido']"
                            label="Nombre"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="7"
                        >
                          <v-text-field
                            v-model="editedItem.descripcion"
                            label="Descripción"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-select
                            v-model="editedItem.duracionfirma"
                            :rules="[v => !!v || 'La duración es requerida']"
                            :items="duracion"
                            item-text="nombre"
                            item-value="id"
                            return-object
                            label="Duración de firmas"
                            @input="setDuracion"
                          />
                        </v-col>
                      </v-row>

                      <v-subheader class="text-h5">
                        Documentos requeridos
                      </v-subheader>
                      <v-dialog
                        v-model="dialogTipo"
                        max-width="60%"
                      >
                        <v-card>
                          <v-card-title>
                            <span class="text-h5">Nuevo tipo de documento</span>
                          </v-card-title>

                          <v-card-text>
                            <v-form>
                              <v-container>
                                <v-row>
                                  <v-col
                                    cols="12"
                                    sm="12"
                                    md="6"
                                  >
                                    <v-text-field
                                      v-model="editedItemTipo.nombre"
                                      :rules="[v => !!v || 'El nombre es requerido']"
                                      label="Nombre"
                                    />
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="12"
                                    md="6"
                                  >
                                    <v-text-field
                                      v-model="editedItemTipo.descripcion"
                                      :rules="[v => !!v || 'La descripción es requerida']"
                                      label="Descripción"
                                    />
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-form>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer />
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="closeTipo"
                            >
                              {{ $t("close") }}
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="saveTipo"
                            >
                              {{ $t("save") }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-expand-transition>
                        <div v-show="existeProcedimiento">
                          <v-row>
                            <v-col
                              cols="12"
                              sm="12"
                              md="6"
                            >
                              <v-select
                                v-model="editedItem.documentos.tipo"
                                :items="tipos"
                                item-text="nombre"
                                item-value="id"
                                prepend-icon="mdi-format-list-bulleted-type"
                                append-outer-icon="mdi-plus"
                                return-object
                                label="Tipo de documento"
                                @input="setTipo"
                                @click:append-outer="newTipo"
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              sm="6"
                              md="4"
                            >
                              <v-text-field
                                v-model="editedItem.documentos.descripcion"
                                label="Descripción del documento"
                              />
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="12"
                              md="6"
                            >
                              <v-file-input
                                v-model="editedItem.documentos.file"
                                label="Seleccione documento"
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              sm="6"
                              md="1"
                            >
                              <v-btn
                                block
                                class="ma-2 white--text"
                                @click="cargarDocumento"
                              >
                                Cargar Documento
                                <v-icon
                                  right
                                  dark
                                >
                                  mdi-cloud-upload
                                </v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="6"
                              md="12"
                            >
                              <v-card
                                max-width="100%"
                                min-height="100%"
                                max-height="300px"
                                class="mx-auto overflow-y-auto elevation-3"
                              >
                                <v-card-title
                                  dense
                                  color="indigo"
                                  dark
                                >
                                  Documentos cargados
                                </v-card-title>

                                <v-list
                                  v-if="editedItem.documentos.length > 0"
                                  two-line
                                >
                                  <template
                                    v-for="(item, i) in editedItem.documentos"
                                  >
                                    <v-list-item :key="item.id">
                                      <v-list-item-avatar>
                                        <v-icon
                                          v-show="item.fileName"
                                          class="blue white--text"
                                          @click="downloadFile(item.id, item.fileName)"
                                        >
                                          mdi-download
                                        </v-icon>
                                      </v-list-item-avatar>

                                      <v-list-item-content>
                                        <v-list-item-title v-text="item.fileName" />
                                        <v-list-item-subtitle
                                          class="text--primary"
                                          v-text="item.tipoName"
                                        />
                                        <v-list-item-subtitle v-text="item.descripcion" />
                                      </v-list-item-content>

                                      <v-list-item-action>
                                        <v-btn icon>
                                          <v-icon
                                            color="error"
                                            @click="removeFile(i, item.id)"
                                          >
                                            mdi-delete
                                          </v-icon>
                                        </v-btn>
                                      </v-list-item-action>
                                    </v-list-item>
                                  </template>
                                </v-list>
                                <v-card-text v-else>
                                  <p
                                    class="text-center text-h3 mt-5 grey--text"
                                  >
                                    Aún no tenemos documentos cargados.
                                  </p>
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                        </div>
                      </v-expand-transition>
                    </v-container>
                  </v-form>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                  >
                    {{ $t("close") }}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                  >
                    {{ $t("save") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.acciones="{ item }">
          <v-icon
            small
            class="mr-2"
            color="success"
            @click="editItem(item)"
          >
            mdi-pencil-outline
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn
            color="primary"
            @click="initialize"
          >
            Recargar
          </v-btn>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
  import MaestrosTipoDocApi from '@/services/api/MaestrosTipoDocumento'
  import ProcedimientosApi from '@/services/api/Procedimientos'
  import MaestrosPerfilFirmasApi from '@/services/api/MaestrosPerfilFirmas'

  export default {
    name: 'Procedimientos',

    data () {
      return {
        valid: true,
        validTipo: true,
        validDoc: true,
        snackbar: false,
        colorSnak: 'info',
        text: '',
        timeout: 3000,
        dialog: false,
        dialogTipo: false,
        dialogDoc: false,
        procedimientos: [],
        documentos: [],
        editedIndex: -1,
        editedIndexDoc: -1,
        lazy: false,
        duracion: [],
        tipos: [],
        headers: [
          {
            text: 'Nombre',
            align: 'left',
            sortable: false,
            value: 'nombre',
          },
          {
            text: 'Descripción',
            align: 'left',
            sortable: false,
            value: 'descripcion',
          },
          {
            text: 'Participantes',
            align: 'left',
            sortable: false,
            value: 'participantes',
          },
          {
            text: 'Duración',
            align: 'left',
            sortable: false,
            value: 'duracionfirmaName',
          },
          {
            text: 'Acciones',
            value: 'acciones',
            sortable: false,
          },
        ],
        headersDoc: [
          {
            text: 'Nombre',
            align: 'left',
            sortable: false,
            value: 'nombre',
          },
          {
            text: 'Descripción',
            align: 'left',
            sortable: false,
            value: 'descripcion',
          },
          {
            text: 'Formato',
            align: 'left',
            sortable: false,
            value: 'fileName',
          },
          {
            text: 'Estado',
            align: 'left',
            sortable: false,
            value: 'estado',
          },
          {
            text: 'Acciones',
            value: 'acciones',
            sortable: false,
          },
        ],
        editedItem: {
          id: '',
          nombre: '',
          descripcion: '',
          participantes: '',
          duracionfirma: '',
          documentos: [],
          tipos: [],
        },
        editedItemTipo: {
          id: '',
          nombre: '',
          descripcion: '',
        },
        defaultItem: {
          id: '',
          nombre: '',
          descripcion: '',
          participantes: '',
          duracionfirma: '',
          documentos: [],
          tipos: [],
        },
      }
    },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo procedimiento' : 'Modificar procedimiento'
      },
      existeProcedimiento () {
        if (!this.editedItem.id) {
          return false
        } else {
          return true
        }
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogTipo (val) {
        val || this.closeTipo()
      },
      dialogDoc (val) {
        val || this.closeDoc()
      },
    },

    created () {
      this.initialize()
    },

    methods: {
      fetchTipoDoc () {
        MaestrosTipoDocApi.getAllTipoDoc()
          .then(tipos => {
            this.tipos = tipos
          })
          .catch(error => console.log(error))
      },
      fetchDuracionFirmas () {
        MaestrosPerfilFirmasApi.getAllPerfilFirmas()
          .then(duracion => {
            this.duracion = duracion
          })
          .catch(error => console.log(error))
      },
      fetchProcedimientos () {
        ProcedimientosApi.getAllProcedimientos()
          .then(procedimientos => {
            this.procedimientos = procedimientos
          })
          .catch(error => console.log(error))
      },
      initialize () {
        this.fetchTipoDoc()
        this.fetchDuracionFirmas()
        this.fetchProcedimientos()
      },

      setDuracion (i) {
        this.editedItem.duracionfirma = i.id
        this.editedItem.duracionfirmaName = i.nombre
      },

      setTipo (i) {
        const tipo = this.tipos.find(e => e.id === i.id)
        this.editedItem.documentos.descripcion = tipo.descripcion
      },

      save () {
        if (this.$refs.form.validate()) {
          if (this.editedIndex > -1) {
            const updProcedimientos = this.editedItem
            const indexItem = this.editedIndex
            ProcedimientosApi.updateProcedimiento(this.editedItem.id, this.editedItem)
              .then(response => {
                Object.assign(this.procedimientos[indexItem], updProcedimientos)
                this.text = 'Registro modificado correctamente'
                this.snackbar = true
                this.colorSnak = 'success'
              })
              .catch(error => {
                console.log(error)
                this.text = 'El registro no puede ser modificado en este momento'
                this.colorSnak = 'error'
                this.snackbar = true
                this.editedIndex = -1
              })
          } else {
            const addProcedimientos = this.editedItem
            ProcedimientosApi.addProcedimiento(this.editedItem)
              .then(response => {
                addProcedimientos.id = response.id
                this.procedimientos.push(addProcedimientos)
                this.text = 'Registro creado correctamente'
                this.colorSnak = 'success'
                this.snackbar = true
                this.editedIndex = this.procedimientos.length - 1
                this.editedItem = Object.assign({}, this.procedimientos[this.editedIndex])
              })
              .catch(error => {
                console.log(error)
                this.text = 'El registro no puede ser creado en este momento'
                this.colorSnak = 'error'
                this.snackbar = true
              })
          }
          // this.close()
        }
      },

      editItem (item) {
        this.editedIndex = this.procedimientos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      close () {
        if (this.editedItem.id && this.editedItem.documentos.length <= 0) {
          this.text = 'Debes registrar al menos un documento para continuar.'
          this.colorSnak = 'warning'
          this.snackbar = true
          return false
        }
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      newTipo () {
        this.editedItemTipo.nombre = ''
        this.editedItemTipo.descripcion = ''
        this.dialogTipo = true
      },

      saveTipo () {
        const addTipos = this.editedItemTipo
        MaestrosTipoDocApi.addTipoDoc(this.editedItemTipo)
          .then(response => {
            addTipos.id = response.id
            this.tipos.push(addTipos)
            this.text = 'Registro creado correctamente'
            this.colorSnak = 'success'
            this.snackbar = true
            // this.editedIndex = -1
          })
          .catch(error => {
            console.log(error)
            this.text = 'El registro no puede ser creado en este momento'
            this.colorSnak = 'error'
            this.snackbar = true
          })
        this.closeTipo()
      },

      closeTipo () {
        this.dialogTipo = false
      },

      closeDoc () {
        this.dialogDoc = false
      },

      cargarDocumento () {
        const value = this.editedItem.documentos.tipo.id
        if (!value) {
          this.text = 'Debes seleccionar un tipo de documento'
          this.colorSnak = 'info'
          this.snackbar = true
          return
        }

        if (!this.editedItem.id) {
          this.text = 'Antes debes crear el procedimiento.'
          this.colorSnak = 'info'
          this.snackbar = true
          return
        }

        const existe = this.editedItem.documentos.find(e => e.tipo === this.editedItem.documentos.tipo.id || e.tipo === this.editedItem.documentos.tipo.nombre)

        if (existe) {
          this.text = 'El documento eligido ya existe, seleccione otro para continuar.'
          this.colorSnak = 'warning'
          this.snackbar = true
          return
        }

        const formData = new FormData()
        if (this.editedItem.documentos.file) {
          formData.append('_file', this.editedItem.documentos.file)
        } else {
          formData.append('_file', null)
        }

        formData.append('procedimiento', this.editedItem.id)
        formData.append('tipo', this.editedItem.documentos.tipo.id)
        formData.append('nombre', this.editedItem.documentos.descripcion)
        formData.append('descripcion', this.editedItem.documentos.descripcion)

        ProcedimientosApi.uploadFile(formData)
          .then(response => {
            const name = this.tipos.find(e => e.id === this.editedItem.documentos.tipo.id)
            this.editedItem.documentos.push({
              id: response.id,
              nombre: (this.editedItem.documentos.file) ? this.editedItem.documentos.file.name : '',
              tipo: name.nombre,
              descripcion: this.editedItem.documentos.descripcion,
              file: response.file,
            })
            this.editedItem.documentos.file = null
            this.editedItem.documentos.tipo = ''
            this.editedItem.documentos.descripcion = ''
            this.text = 'Documento cargado correctamente'
            this.colorSnak = 'success'
            this.snackbar = true
          })
          .catch(error => {
            console.log(error)
            this.text = 'El documento no puede ser cargado en este momento'
            this.colorSnak = 'error'
            this.snackbar = true
          })
      },

      removeFile (i, item) {
        const index = i
        if (confirm('Realmente desea eliminar este registro?')) {
          ProcedimientosApi.deleteFile(item)
            .then(response => {
              this.editedItem.documentos.splice(index, 1)
              this.text = 'Registro eliminado correctamente'
              this.colorSnak = 'success'
              this.snackbar = true
            })
            .catch(error => {
              console.log(error)
              this.text = 'Lo sentimos, el registro no puede ser eliminado en este momento'
              this.colorSnak = 'error'
              this.snackbar = true
            })
        }
      },

      forceFileDownload (response, nombre) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', nombre)
        document.body.appendChild(link)
        link.click()
      },

      downloadFile (id, name) {
        const nombre = name
        ProcedimientosApi.downloadFile(id)
          .then(file => {
            this.forceFileDownload(file, nombre)
          })
          .catch(error => console.log(error))
      },
    },
  }
</script>
